import React from 'react';

const dashboard = () => {
  return (
    <div>
      <dir>
        <p>Dashboard</p>
      </dir>
    </div>
  );
};

export default dashboard;
