import React, { Component } from "react";

class HopdDataVis extends Component {
  render() {
    return (
      <div>
        <h1>Data Visualization Page</h1>
      </div>
    );
  }
}

export default HopdDataVis;
